export const categories = [
  {
    id: 21,
    count: 0,
    name: "Bootstrap",
    slug: "bootstrap",
    taxonomy: "category",
  },
  {
    id: 12,
    count: 2,
    name: "Business Solutions",
    slug: "business-solutions",
    taxonomy: "category",
  },
  {
    id: 39,
    count: 7,
    name: "Code Challenges",
    slug: "code-challenges",
  },
  {
    id: 20,
    count: 0,
    name: "CSS",
    slug: "css",
  },
  {
    id: 36,
    count: 1,
    name: "Ethics",
    slug: "ethics",
  },
  {
    id: 23,
    count: 0,
    name: "Facebook",
    slug: "facebook",
  },
  {
    id: 7,
    count: 2,
    name: "Financial Calculator",
    slug: "financial-calculator",
  },
  {
    id: 19,
    count: 0,
    name: "HTML",
    slug: "html",
  },
  {
    id: 14,
    count: 4,
    name: "Java",
    slug: "java",
  },
  {
    id: 15,
    count: 0,
    name: "JavaScript",
    slug: "javascript",
  },
  {
    id: 16,
    count: 0,
    name: "jQuery",
    slug: "jquery",
  },
  {
    id: 26,
    count: 5,
    name: "Math Solutions",
    slug: "math-solutions",
  },
  {
    id: 18,
    count: 0,
    name: "MySQL",
    slug: "mysql",
  },
  {
    id: 11,
    count: 1,
    name: "Needs Assessment",
    slug: "needs-assessment",
  },
  {
    id: 13,
    count: 1,
    name: "Non-Profit Ideas",
    slug: "non-profit-ideas",
  },
  {
    id: 6,
    count: 2,
    name: "Open Source Software",
    slug: "open-source",
  },
  {
    id: 17,
    count: 0,
    name: "Php",
    slug: "php",
  },
  {
    id: 5,
    count: 4,
    name: "Python",
    slug: "python",
  },
  {
    id: 28,
    count: 2,
    name: "Recursion",
    slug: "recursion",
  },
  {
    id: 8,
    count: 0,
    name: "Security",
    slug: "security",
  },
  {
    id: 24,
    count: 0,
    name: "Social Media",
    slug: "social-media",
  },
  {
    id: 4,
    count: 9,
    name: "Software",
    slug: "software",
  },
  {
    id: 9,
    count: 7,
    name: "Software Development",
    slug: "software-development",
  },
  {
    id: 37,
    count: 5,
    name: "Software Engineering",
    slug: "software-engineering",
  },
  {
    id: 29,
    count: 1,
    name: "Software Testing",
    slug: "software-testing",
  },
  {
    id: 38,
    count: 5,
    name: "System Design",
    slug: "system-design",
  },
  {
    id: 1,
    count: 0,
    name: "Uncategorized",
    slug: "uncategorized",
  },
  {
    id: 22,
    count: 0,
    name: "Web Development",
    slug: "web-development",
  },
  {
    id: 25,
    count: 0,
    name: "Web Visibility",
    slug: "web-visibility",
  },
];

export default categories;
