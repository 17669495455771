import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer>
        <p>&copy; 2022 &nbsp;MD Web Technologies</p>
      </footer>
    );
  }
}

export default Footer;
